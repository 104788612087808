import Vue                     from 'vue';
import GetTextPlugin           from 'vue-gettext';
import dayjs                   from 'dayjs';
import {
    yccBootConfig,
    YuccaBootConfig,
}                              from './config/yccBootConfig';
import gettextOptions          from './config/gettextOptions';
import router                  from './router';
import store                   from './store/store';
import YccConfigPlugin         from './extends/YccConfigPlugin';
import { isMobile }            from './extends/browserEnv';
import App                     from './App.vue';
import { SYSTEM_SETTINGS_URL } from './constants/api';
import httpClient              from './config/httpClient';
import initHint                from './components/ycc-hint/initHint';

import './config/setVueConfig';
import './config/setPlugins';
import './scss/index.scss';

Vue.config.devtools = process.env.NODE_ENV === 'development';

if (!window.bus) {
    window.bus = new Vue();
}

if (!isMobile) {
    initHint(
        {
            hintId  : 'hint-1',
            position: 'bottom',
            hint    : '',
            isDark  : true,
            maxWidth: 250,
        },
    );
}

if (isMobile) {
    document.body.classList.add('is-mobile');
}
else {
    document.body.classList.add('is-desktop');
}

function createApp (config) {
    Vue.use(YccConfigPlugin, { config });
    Vue.use(GetTextPlugin, gettextOptions(config.language));
    dayjs.locale(config.language);
    store.state.config = config;

    /* eslint-disable no-new */
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
}

if (yccBootConfig) {
    createApp(yccBootConfig);
}
else {
    httpClient
        .get(SYSTEM_SETTINGS_URL)
        .then((res) => {
            createApp(new YuccaBootConfig(res.data, {}));
        })
        .catch(() => {
            createApp(new YuccaBootConfig({}, {}));
        });
}
